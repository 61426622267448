exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lectures-js": () => import("./../../../src/pages/lectures.js" /* webpackChunkName: "component---src-pages-lectures-js" */),
  "component---src-pages-lectures-markdown-remark-frontmatter-lecture-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/lectures/{markdownRemark.frontmatter__lecture}/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-lectures-markdown-remark-frontmatter-lecture-markdown-remark-parent-file-name-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-student-projects-js": () => import("./../../../src/pages/student-projects.js" /* webpackChunkName: "component---src-pages-student-projects-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

